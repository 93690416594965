<template>
    <div>
        <page-title show-previous-button url-previous="/settings/cashierSettings/customerDisplay" :heading="$t('settings.lang_customerDisplays') " :subheading="$t('settings.lang_customerDisplays')" :icon=icon></page-title>

        <div class="app-main__inner">
            <Template></Template>
        </div>
    </div>
</template>

<script>
    import PageTitle from "../../Layout/Components/PageTitle.vue";
    import Template from "../../components/settings/customerdisplay/Template";

    export default {
        name: "CustomerdisplayTemplate",

        components: {
            Template,
            PageTitle
        },

        data: () => ({
            icon: 'pe-7s-monitor icon-gradient bg-tempting-azure'
        })
    }
</script>